import { MessageBox, Message } from 'element-ui'

const confirm = (
  content: string,
  confirmButtonText = '确认',
  cancelButtonText = '取消'
) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(content, '提示', {
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      type: 'warning'
    })
      .then(() => {
        resolve(true)
      })
      .catch(() => {
        reject()
      })
  })
}

export default { confirm }
